var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"background-container",style:({
    backgroundImage:
      'url(' + require('@/assets/images/' + _vm.background_mobile) + ')',
    height: '100vh',
    width: '100vw',
    overflow: 'auto',
    backgroundSize: 'cover',
  }),attrs:{"fluid":""}},[_c('div',{staticClass:"d-flex flex-column align-center justify-center",staticStyle:{"margin-top":"20px","margin-right":"5%","margin-left":"5%"}},[_c('h2',{staticClass:"text-center mb-4",style:({
        color: _vm.WEBAPPPRO_TEXT_COLOR_1,
        fontFamily: _vm.WEBAPPPRO_FONT_FAMILY_1,
        fontSize: _vm.WEBAPPPRO_FONT_SIZE_1,
        fontWeight: 700,
        marginTop: '150px',
      })},[_vm._v(" VOS PHOTOS ")]),_c('div',{staticClass:"text-center",style:({
        color: _vm.WEBAPPPRO_TEXT_COLOR_2,
        fontSize: _vm.WEBAPPPRO_FONT_SIZE_3,
        fontFamily: _vm.WEBAPPPRO_FONT_FAMILY_3,
        'font-weight': 'regular',
      })},[_c('p',[_vm._v("Sélectionnez votre meilleure photo ou refaites-en une")])]),_c('v-row',_vm._l((_vm.validatedImages),function(validatedImage,index){return _c('v-col',{key:validatedImage.id,staticClass:"mx-2",attrs:{"cols":"5"}},[_c('v-sheet',{class:{ selected: _vm.choix[index] },attrs:{"rounded":"lg","width":"150","height":"200"},on:{"click":function($event){return _vm.choice1(index, validatedImage)}}},[_c('v-row',{staticClass:"d-flex align-center flex-column flex-sm-row pa-2"},[_c('v-img',{class:{ imgCho: true, 'selected-image': _vm.choix[index] },attrs:{"src":_vm.getImageSrc(validatedImage.photo, validatedImage.qrcode),"width":"100%","height":"100%","cover":""}})],1)],1)],1)}),1),_c('div',{staticClass:"d-flex justify-center"},[_c('v-btn',{style:({
          color: _vm.WEBAPPPRO_BUTTON_TEXT_COLOR_1,
          boxShadow: _vm.WEBAPPPRO_BUTTON_SHADOW_1,
          borderRadius: _vm.WEBAPPPRO_BUTTON_BORDER_ROUND,
          'text-transform': 'none',
          marginBottom: '20px',
          marginTop: '40px',
          padding: '25px 100px',
          fontFamily: _vm.WEBAPPPRO_FONT_FAMILY_3,
          fontSize: _vm.WEBAPPPRO_BUTTON_FONT_SIZE,
          'font-weight': 'bold',
          'line-height': '18px',
          'letter-spacing': '0em',
          'text-align': 'center',
          width: '327px',
          height: '52px',
        }),attrs:{"color":_vm.WEBAPPPRO_BUTTON_COLOR_1,"disabled":_vm.uploading,"loading":_vm.uploading},on:{"click":_vm.saveAndContinue}},[_vm._v("Continuer ")])],1),_c('div',{style:({
        color: _vm.WEBAPPPRO_BUTTON_COLOR_1,
        fontFamily: _vm.WEBAPPPRO_FONT_FAMILY_3,
        fontSize: _vm.WEBAPPPRO_BUTTON_FONT_SIZE,
        'font-weight': 'bold',
        'line-height': '18px',
      }),attrs:{"color":_vm.WEBAPPPRO_BUTTON_COLOR_1}},[_c('a',{staticClass:"centered-button mt-2",staticStyle:{"color":"inherit","text-decoration":"underline"},attrs:{"href":"./../ShootPhoto.vue"}},[_vm._v(" Reprendre une photo ")])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }